.checklist {
        width: 575px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: auto;

    @media screen and (max-width: 720px) {
        width: 100%;
        .list {
            margin: 10px;
        }
    }
}

.cl-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    @media screen and (max-width: 720px) {
        width: 100%;
    }
}