.reviews {
    width: 350px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px;

    .avatar {
            margin: 10px;
        img {
            border-radius: 50%;
            width: 50px;

            @media screen and (max-width: 720px) {
                width: 75px;
            }
        }
    }

    .checked {
        color: orange;
    }

    .review { 
        width: 280px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        h3 {
            font-size: 1.25rem;
            text-transform: capitalize;
        }
        p {
            font-size: 0.9rem;
            line-height: 1em;
        }
    }

    @media screen and (max-width: 720px) {
        width: 260px;
        flex-direction: column;
        justify-content: flex-start;
    }
}