.flags {
    margin: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.flag-grp {
    margin: 25px;
    width: 175px;
    a {
        text-decoration: none;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: auto; 
        img {
            height: 100px;
            width: 150px;
            max-height: 100%;
            max-width: 100%;
            margin: 10px; 
            box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.3);
        }
        h5 {
            text-align: center;
            margin: 10px;
            
        }
    }
    @media screen and (max-width: 720px) {
        width: max-content;
        a {
            img {
                width: 250px;
                height: 100%;
            }
        }
    }
}