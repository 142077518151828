.about {
    width: 1000px;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;

    .para, .para1 {
        margin: 10px;
        img {
            padding: 10px;
            margin: 10px;
            height: 300px;
            border-radius: 50%;
            float: left;
        }
        .text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            h5 {
                padding: 10px;
                margin: 10px;
                text-align: justify;
                color: #333;
            }
        }
        
        @media screen and (max-width: 720px) {
            display: flex;
            flex-direction: column;
            img {
                height: 250px;
                width: 250px;
                float: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
            }
        }
    }

    .para1 {
        display: flex;
        flex-direction: row-reverse;
        
        @media screen and (max-width: 720px) {
            flex-direction: column;
        }
    }

    @media screen and (max-width: 720px) {
        width: 100%;
    }

    .certi {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: auto;
        img {
            padding: 10px;
            width: 300px;
            height: 100%;
            min-height: 250px;

            @media screen and (max-width: 720px) {
                padding: 20px;
                width: 100%;
            }
        }

        @media screen and (max-width: 720px) {
            flex-direction: column;
        }
    }
}