@import '../components/App.scss';

.Carousels {
    .carousel-indicators {
        display: none;
    }
    .carousel-caption {
        padding: 0;
    }
    img {
        width: 100%;
        height: 600px !important;

        @media screen and (max-width: 720px) {
            height: 180px !important;
        }
    }
}

.service { 
    margin: 75px 0;
    padding: 30px 0;
    h2 {
        text-align: center;
        color: $blue;
        font-weight: bold;
        margin: 25px;
    }
    .icons {
        @include Flex(space-evenly, center, row);
        flex-wrap: wrap;
        margin: 25px;
        .icon {
            @include Flex(center, center, column);
            width: 100px;
            .link {
                text-align: center;
                text-decoration: none;
                img {
                    height: 100px;
                    width: 100px;
                }
                p {
                    margin: 10px;
                    font-size: 1rem;
                    color: $blue;
                }
            }
        }

        @media screen and (max-width: 720px) {
            margin: 0;
            .icon {
                .link {
                    img {
                        height: 75px;
                        width: 75px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 720px) {
        margin: 70px 0;
        padding: 0;
        h2 {
            margin: 15px;
        }
    }
}

.title {
    text-align: center;
    margin: 10px; 
    padding: 10px;
    padding-top: 30px;
    h3 {
        color: $blue;
    }
    p {
        margin: 0;
    }
}

.card-grp {
    //@include Flex(space-evenly, center, row);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 10px;
    padding: 10px;
    height: 425px;
    max-height: 100%;
    
	&::-webkit-scrollbar {
        height: 0.5em !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color: lightblue, $alpha: 0.75); 
    }

    &::-webkit-scrollbar-thumb:hover {
        background: lightblue; 
    }
    
    @media screen and (max-width: 720px) {
        justify-content: flex-start;

        //&::-webkit-scrollbar {
            //display: none;
        //}
    }
}

.parallax, .parallax1 { 
    background-image: url("https://images.unsplash.com/photo-1564507592333-c60657eea523?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80");  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 75px;

    .row {
        margin: 0;
        .col-md-3 {
            p, h2 {
                font-weight: bold;
                color: white;
                margin: 10px;
            } 
            display: flex;
            justify-content: center; 
            flex-direction: column;
        }
        .col-md-3, .col-md-9 {
            width: 100%;
            background-color: rgba($color: $blue, $alpha: 0.5);
            padding: 0;
            padding-left: 5px;
            .card-grp {
                margin: 75px 0;
                @media screen and (max-width: 720px) {
                    margin: 50px 0;
                    margin-right: 10px;
                }
            }
            @media screen and (max-width: 720px) {
                padding: 0;
                //padding-top: 25px;
            }
        }
    }
}

.parallax1 {
    background: url('https://images.unsplash.com/photo-1533204515132-035bc8d4128c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 720px) {
        .row {
            flex-direction: column-reverse;
        }
    }
}
.reviews-grp {
    margin: 150px 0;
    .review-grp {
        padding: 10px;
        height: 100%;
    }

    @media screen and (max-width: 720px) {
        margin: 0;
    }
}