.timeline {
    width: 1000px;

    @media screen and (max-width: 720px) {
        width: 100%;
    }
}

.hide1 {
    display: none;
}
.hide2 {
    display: none;
}
.hide3 {
    display: none;
}
.hide4 {
    display: none;
}
.hide5 {
    display: none;
}
.hide6 {
    display: none;
}
.hide7 {
    display: none;
}
.hide8 {
    display: none;
}
.hide9 {
    display: none;
}
.hide10 {
    display: none;
}