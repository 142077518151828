.contact {
    .form {
        width: 100%;
        margin: 10px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        .form-grp {
            display: flex;
            flex-direction: column;
            label {
                margin: 8px 0;
                color: black;
                i {
                    margin-right: 5px;
                }
            }
            input, textarea {
                width: 450px;
                padding: 5px;
                border: 1px solid #777;
                border-radius: 2.5px;
            }
            button {
                margin: 8px;
                width: 100px;
                background: #555;
                color: #fff;
                border: none;
                i {
                    margin-left: 5px;
                }
            }
            span {
                color: #999;
            }
            @media screen and (max-width: 720px) {
                input, textarea {
                    width: 300px;
                }
            }
        }
    }
    .row {
        margin: 0;
        .col-md-6 {
            padding: 0;
            .title {
                text-align: left;
                padding: 0;
            }
        }
        .address {
            padding: 50px;
            @media screen and (max-width: 720px) {
                padding: 0 15px;
            }
        }
        @media screen and (max-width: 720px) {
            display: flex;
            flex-direction: column;
        }
    }
}

.map {
    border: 0;
    width: 96%;
    height: 400px;
    margin: 25px;
    @media screen and (max-width: 720px) {
        height: 250px;
        margin: 5px;
    }
}