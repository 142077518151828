.flights {
    background-image: url("https://images.unsplash.com/photo-1549298385-f62e042575a8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80");  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .title {
        margin: 0;
    }
}

.navigation {
    .nav-tabs {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    a {
        color: white;
    }
}

.form {
    width: 100%;
    padding: 20px;
    //padding-bottom: 1.6%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    input, select {
        border-radius: 5px;
    }
    label {
        color: white;
    }
    .form-grp2 {
        width: 450px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .form-grp3 {
            display: flex;
            flex-direction: column;
            label {
                color: white;
            }
            input, select {
                width: 200px;
                border: 1px solid;
                border-color: hsl(0, 0%, 80%);
            }
            input[type="date"], input[type="number"], select {
                height: 36px;
                width: 200px;
                padding: 5px;
                background-color: white;
            }
        }
        @media screen and (max-width: 720px) { 
            width: 250px;
            display: flex;
            flex-direction: column;
            .form-grp3, .form-grp4 {
                input, select {
                    width: 250px;
                }
                input[type="date"], input[type="number"], select {
                    height: 36px;
                    width: 250px;
                    padding: 5px;
                }
                input[type="date"]:before {
                    content: attr(placeholder) !important;
                    color: #777;
                    margin-right: 0.5em;
                }
                input[type="date"]:focus:before,
                input[type="date"]:valid:before {
                    content: "";
                }
            }
        }
    }
    .group {
        width: 1000px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .form-icon {
            display: flex;
            align-items: center;
            .material-icons {
                padding-top: 25px;
                font-size: 2.5rem;
                @media screen and (max-width: 720px) {
                    transform: rotate(90deg);
                    padding: 5px;
                }            
            }        
        }
        .form-grp1 {
            display: flex;
            flex-direction: column;
            .css-2b097c-container {
                width: 450px;
                .css-1hwfws3 {
                width: 450px;
                }

                @media screen and (max-width: 720px) {
                    width: 250px;
                }
            }
        }
        @media screen and (max-width: 720px) {
            width: 100%;
            padding: 5px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
    .form-grp {
        display: flex;
        flex-direction: column;
        label {
            margin: 8px 0;
            i {
                margin-right: 5px;
            }
        }
        input, textarea {
            width: 450px;
            padding: 5px;
            border: 1px solid;
            border-color: hsl(0, 0%, 80%);
            resize: none;
            
        }
        button {
            margin: 10px;
            padding: 10px;
            width: 150px;
            border-radius: 15px;
            background: #f37638;
            color: #fff;
            border: none;
            font-size: 1.2rem;
            font-weight: bold;
            i {
                margin-left: 5px;
            }
        }
        span {
            color: #fff;
        }
        @media screen and (max-width: 720px) {
            input, textarea {
                width: 250px;
            }
        }
    }
}

.addForm {
    width: 1000px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .addform-grp {
        display: flex;
        flex-direction: column;
        input {
            width: 250px;
            padding: 5px;
            border: 1px solid;
            border-color: hsl(0, 0%, 80%);
            resize: none;
        }
        input[type="date"] {
            background-color: white;
        }
        
        @media screen and (max-width: 720px) {
            input{
                width: 250px;
            }
            input[type="date"]:before {
                content: attr(placeholder) !important;
                color: #777;
                margin-right: 0.5em;
            }
            input[type="date"]:focus:before,
            input[type="date"]:valid:before {
                content: "";
            }
        }
    }
    
    @media screen and (max-width: 720px) {
        width: 100%;
        padding: 25px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}