@import './Card.scss';

.package-card {
    .card-grp {
        justify-content: flex-start;
        flex-wrap: wrap;
        min-height: 475px;
        height: 100%;

        @media screen and (max-width: 720px) {
            justify-content: center;
            height: 100%;
        }
    }
}

/*
@media screen and (max-width: 720px) {
    .verticalCard {
        .cardImg {
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}
*/