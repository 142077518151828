@import '../components/App.scss';

.NavBar {
    .navbar-light .navbar-nav .nav-link {
        color: rgba(0,0,0,.5);
        &:hover {
            color: #14427a;
        }
    }
    .bg-light {
        background-color: $background !important;
        box-shadow: 5px 5px 20px rgba($color: #000000, $alpha: 0.1);
    }
    .navbar-brand {
        @include Flex(space-between, center, row);
        flex-wrap: nowrap;
        font-size: 1em;
        color: $blue;
        margin: auto;
        margin-left: 0;
    }
    .brand {
        img {
            height: 40px;
            width: 40px;
        }
        span {
            width: 100px !important;
            white-space: normal;
        }
    }
}