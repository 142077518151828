.bus {
    height: 90vh;
    background: url("https://blogs.lse.ac.uk/lsereviewofbooks/files/2016/01/Roads-Image.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 750px;
        padding: 10px;
        margin: 0 auto;
        background-color: #fff;
        h3 {
            color: #14427a;
            font-size: 2rem;
            font-weight: bold;
        }
        p {
            color: #14427a;
        }
        @media screen and (max-width: 720px) {
            width: 100%;
            margin-top: 10px;
            background-color: transparentize($color: #fff, $amount: 1);
            h3,p {
                color: #fff;
            }
        }
    }
    .bus-form {
        //background-color: rgba($color: #14427a, $alpha: 0.4);
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        //box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.3); 
        .b-form {
            height: 50px;
            .form-grp {
                //padding: 25px;
                margin: 25px 0;
                input {
                    width: 200px;
                    height: 52px;
                    padding: 10px;
                    border: none;
                }
                .a {
                    padding: 14px;
                    text-decoration: none;
                    background: #14427A;
                    color: white;
                    border: none;
                }
            }
            @media screen and (max-width: 720px) {
                height: 100%;
            }
        }
        @media screen and (max-width: 720px) {
            .form-grp {
                padding: 5px;
                margin: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border: none;
                input {
                    width: 300px;
                    margin: 5px;
                    border: 1px solid #888;
                }
                a {
                    margin: 10px;
                    padding: 10px;
                }
            }
        }
    }
        @media screen and (max-width: 720px) {
            height: 100%;
        }
}

.img-grp {
    //background-color: rgba($color: #7777, $alpha: 0.5);
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    img {
        margin: 10px;
        height: 100%;
        width: 300px;
        box-shadow: 0 0 10px rgba($color: #000, $alpha: 0.5);
    }
    @media screen and (max-width: 720px) {
        margin: 0;
        justify-content: flex-start;
    }
}