@import '../components/App.scss';

.footer {
    width: 100%;
    background-color: $blue;
    .social {
        padding: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        a {
            margin: 10px;
            font-size: 1.5rem;
            color: white;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                color: #ea0c17;
            }
        }
    }
    .copy {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: auto;
        padding: 20px 0;
        padding-bottom: 35px;

        p {
            color: white;
            text-align: center;
            a {
                text-decoration: none;
                color: #999;
                &:hover {
                    color: white;
                }
            }
        }
        @media screen and (max-width: 720px) {
            flex-direction: column;
            p {
                width: 250px;
            }
        }
    }

    .links {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        margin: auto;

        .ul {
            width: 100%;
            margin: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            ul {
                margin: 10px;
                padding: 10px;
                a {
                    text-decoration: none;
                    li {
                        list-style: none;
                        color: #fff;
                        &:hover {
                            color: #ea0c17;
                        }
                    }
                }
            }
                p {
                    width: 500px;
                    color: #fff;

                    a {
                        color: #fff;
                        &:hover {
                            color: #ea0c17;
                        }
                    }

                    @media screen and (max-width: 720px) {
                        width: 100%;
                    }
                }
        }
        @media screen and (max-width: 720px) {
            flex-direction: column;
            .ul {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}