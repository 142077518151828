.button-grp {
    width: 1000px;
    margin: 25px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    a {
        text-decoration: none;
        padding: 15px;
        margin: 5px;
        color: white;
    }
    .button {
        width: 250px;
        text-align: center;
        background-color: #14427a;
        border-radius: 25px;
    }
    @media screen and (max-width: 720px) {
        width: 100%;
        margin: 5px;
        justify-content: center;
        flex-direction: column;
    }
}