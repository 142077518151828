.card-grp-others {
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;

    @media screen and (max-width: 720px) {
        width: 100%;
    }
}