@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

$blue : #14427a;
$background : white;

@mixin Flex($content, $align, $direction) {
    display: flex;
    justify-content: $content;
    align-items: $align;
    flex-direction: $direction;
}

* {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

body, html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

	&::-webkit-scrollbar {
        height: 0.5em !important;
        width: 0.5em !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color: $blue, $alpha: 0.75); 
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $blue; 
    }
}