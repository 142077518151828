.verticalCard {
    margin: 10px;
    //width: 300px;
    height: min-content; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    background-color: white;
    transition: transform .2s;

    &:hover {
        transform: scale(1.1);
    }
    .cardImg {
        width: 300px;
        img {
            min-height: 100%;
            min-width: 100%;
            width: 100%;
            height: 250px;
            padding: 10px;
        }
    }
    .cardBody {
        margin: 15px;
        width: 250px;
        .cardTitle {
            width: 100%;
            color: #222;
            text-align: left;
        }
        a {
            width: 250px;
            text-decoration: none;
            padding: 5px;
        }
        .button {
            width: 250px;
            background-color: #555;
            color: white;
            border: none;
            &:hover {
                width: 275px;
            }
        }
    }

    @media screen and (max-width: 720px) {
        //width: 340px !important;
        &:hover {
            transform: none;
            box-shadow: 0 0 25px rgba($color: #14427a, $alpha: 0.5);
        }
        .cardImg {
            width: 290px;
            img {
                width: 100%;
                //width: 250px;
            }
        }
    }
}
