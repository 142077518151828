.package-details {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    img {
        width: 90%;
        height: 500px;
        @media screen and (max-width: 720px) {
            width: 100%;
            height: 100%;
        }
    }
    .pd-btn {
        margin: 10px;
    }
}