.Hotel {
    //height: 100%;
    background-image: url("https://io.telkomuniversity.ac.id/wp-content/uploads/2015/03/Dubai-e1434096715956.jpg");  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .form {
        .group {
                .date {
                    input[type="date"]{
                        width: 200px;
                    @media screen and (max-width: 720px) {
                        width: 250px;
                    }
                }
            } 
        }
    }
    @media screen and (max-width: 720px) {
        height: 100%;
    }
}