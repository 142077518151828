.form {
    width: 100%;
    margin: 10px 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    .f-grp {
        display: flex;
        flex-direction: column;
        label {
            margin: 8px 0;
            color: black;
            i {
                margin-right: 5px;
            }
        }
        input, textarea {
            width: 450px;
            padding: 5px;
            border: 1px solid #777;
            border-radius: 2.5px;
        }
        .button {
            margin-top: 10px; 
            width: 100px;
            background: #555;
            color: #fff;
            border: none;
        }
        span {
            color: #999;
        }
        @media screen and (max-width: 720px) {
            input, textarea {
                width: 250px;
            }
        }
    }
}