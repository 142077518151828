@import '../components/App.scss';

.horizontalCard {
    margin: 15px;
    width: 400px;
    height: 190px; 
    @include Flex(center, center, row);
    align-items: flex-start;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    background-color: white;
    .h-cardImg {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        img {
            min-height: 100%;
            min-width: 100%;
            width: 100%;
            height: 200px;
            padding: 10px;
        }
    }
    .h-cardBody {
        width: 300px;
        margin: 15px; 
        .h-cardTitle {
            color: #222;
            text-align: left;
        }
        a {
            width: 250px;
            text-decoration: none;
            padding: 5px;
        }
        .h-button {
            width: 250px;
            background-color: #555;
            color: white;
            border: none;
            &:hover {
                width: 275px;
            }
        }
    }

    @media screen and (max-width: 720px) {
        width: 100%;
        .h-cardImg {
            img {
                height: 100px;
                //width: 25px;
            }
        }
    }
}
